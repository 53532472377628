import React, { useContext } from 'react';
import { useWaitingTimes } from '../../../../../hooks/useWaitingTimes';
import { useParam } from '../../../../../hooks/useParam';
import { rideImages } from '../../../../../data/rideImages';
import { RideStateComponent } from '../../../../ride/RideStateComponent';
import { Ride } from '../../../../../types';
import { ErrorDisplay } from '../../../../ride/ErrorDisplay';
import { ThemeContext } from '../../../../../context/ThemeContext';

/**
 * Waiting time screen with large ride images and overlay with ride name and waittime/status.
 * Is currently live at the Guest Service location on a big TV screen.
 */
export const WaitTimeScreenGridVideo = () => {
    const theme = useContext(ThemeContext);
    const isTestMode = useParam('isTestMode') === 'true';
    const isStaging = useParam('isStaging') === 'true';
    const ridesQuery = useWaitingTimes({
        isTestMode,
        isStaging,
        endpoint: 'cawh',
        skipNotOperational: true,
        tags: ['wh_screen_grid_video'],
        sortMode: 'waitTimeReversed'
    });
    return (
        <>
            {ridesQuery.isSuccess && (
                <div>
                    <div className={`grid w-[1920px] h-[1080px] bg-black `}>
                        <div className="grid grid-cols-[1314px_607px]">
                            <div className={`grid grid-cols-3`}>
                                {ridesQuery.data.map((ride: Ride) => (
                                    <div>
                                        <Attraction
                                            openingTime={ride.openingTime}
                                            name={ride.name}
                                            rideTags={ride.tags}
                                            image={rideImages['cawh'][theme === 'halloween' ? 'hfn' : 'default'][ride.name]}
                                            waitTimeMins={ride.waitTimeMins}
                                            state={ride.state}
                                        />
                                    </div>
                                ))}
                            </div>
                            <video
                                src={
                                    theme === 'halloween'
                                        ? `https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/video-hfn.mp4?alt=media`
                                        : `https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/video.mp4?alt=media`
                                }
                                className="h-[1080px] w-[607px]"
                                autoPlay
                                loop
                                muted
                            />
                        </div>
                    </div>
                </div>
            )}
            {ridesQuery.isError && <ErrorDisplay />}
        </>
    );
};

const Attraction = ({
    name,
    image,
    waitTimeMins,
    rideTags,
    state,
    openingTime,
    rideWithLights = false
}: {
    name: string;
    image: string;
    rideTags?: string[];
    waitTimeMins: number;
    state: string;
    openingTime: string | null;
    rideWithLights?: boolean;
}) => {
    return (
        <div
            className="h-[360px] relative"
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className="absolute w-full h-full bg-gradient-to-t from-black via-transparent to-transparent" />
            <div className="flex flex-col h-full p-8 text-white relative">
                <h1 className="font-walibi uppercase text-4xl mt-auto mb-1 drop-shadow-[0_4px_1px_rgba(0,0,0,0.25)]">{name}</h1>
                {state === 'open' ? (
                    <p className="font-avenir_black text-3xl">{Math.max(5, waitTimeMins)} min</p>
                ) : (
                    <p className="font-avenir_black text-3xl">
                        <RideStateComponent state={state} openingTime={openingTime} />
                    </p>
                )}
            </div>
        </div>
    );
};
