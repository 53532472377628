import React from 'react';
import './fonts.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { WaitTimeScreenGrid } from './components/screen/cawh/waittime/grid/WaitTimeScreenGrid';
import { WaitTimeScreenTablePortrait } from './components/screen/cawh/waittime/table/WaitTimeScreenTablePortrait';
import { WaitTimeScreenTableLandscape } from './components/screen/cawh/waittime/table/WaitTimeScreenTableLandscape';
import { WaitTimeScreenTable } from './components/screen/cawh/waittime/table/WaitTimeScreenTable';
import { HalloweenStock } from './components/screen/cawh/halloween/HalloweenStock';
import { LocalizationProvider } from './context/LocalizationContext';
import { WaitTimeScreenTableAllRides } from './components/screen/cawh/waittime/table/WaitTimeScreenTableAllRides';
import { WaitTimeScreenGridVideo } from './components/screen/cawh/waittime/grid/WaitTimeScreenGridVideo';
import { ThemeContextProvider } from './context/ThemeContext';

const queryClient = new QueryClient();

const Home = () => {
    return (
        <div className="p-4 flex flex-col gap-4">
            <h1 className="text-xl font-bold">Pages</h1>
            {routes.map((route, index) => (
                <div>
                    <a key={index} className="text-blue-500 hover:underline" href={route.path}>
                        <span className="font-bold">{route.title}</span> ({route.path})
                    </a>
                </div>
            ))}
        </div>
    );
};

export const routes = [
    { path: '/', element: <Home />, title: 'Home' },
    { path: '/screen/cawh/waittime/grid', element: <WaitTimeScreenGrid />, title: 'Grid Guest Service' },
    { path: '/screen/cawh/waittime/grid/video', element: <WaitTimeScreenGridVideo />, title: 'Grid Guest Service Video' },
    { path: '/screen/cawh/waittime/table/portrait', element: <WaitTimeScreenTablePortrait />, title: 'Table Park Vertical Screens' },
    { path: '/screen/cawh/waittime/table/landscape', element: <WaitTimeScreenTableLandscape />, title: 'Table Park Horizontal Screens' },
    { path: '/screen/cawh/halloween', element: <HalloweenStock />, title: 'Halloween Fright Night product stock' },
    { path: '/screen/cawh/waittime/table/allrides', element: <WaitTimeScreenTableAllRides />, title: 'Table Park All Rides' },
    { path: '/screen/cawh/waittime/table', element: <WaitTimeScreenTable />, title: 'Table (no background)' },
    // keep these links for backward compatibility
    { path: '/big', element: <WaitTimeScreenGrid />, title: 'Grid Guest Service (old link)' },
    { path: '/portrait', element: <WaitTimeScreenTablePortrait />, title: 'Table Park Vertical Screens (old link)' },
    { path: '/landscape', element: <WaitTimeScreenTableLandscape />, title: 'Table Park Horizontal Screens (old link)' }
];

const router = createBrowserRouter(routes);

function App() {
    return (
        <ThemeContextProvider>
            <LocalizationProvider>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </LocalizationProvider>
        </ThemeContextProvider>
    );
}

export default App;
