export const rideImages: {
    [park: string]: {
        [theme: string]: {
            [ride: string]: string;
        };
    };
} = {
    cawh: {
        default: {
            Blast: '/imgs/cawh/default/blast1.jpg',
            Condor: '/imgs/cawh/default/condor1.jpg',
            'Crazy River': '/imgs/cawh/default/crazyriver1.jpg',
            'Eat My Dust': '/imgs/cawh/default/eatmydust2.jpg',
            'El Rio Grande': '/imgs/cawh/default/elriogrande1.jpg',
            Goliath: '/imgs/cawh/default/goliath1.jpg',
            'Lost Gravity': '/imgs/cawh/default/lostgravity1.jpg',
            'Space Shot': '/imgs/cawh/default/spaceshot2.jpg',
            'Speed of Sound': '/imgs/cawh/default/speedofsound2.jpg',
            UNTAMED: '/imgs/cawh/default/untamed1.jpg',
            'Xpress: Platform 13': '/imgs/cawh/default/xpress1.jpg',
            "Merlin's Magic Castle": '/imgs/cawh/default/merlin1.jpg'
        },
        bright_nights: {
            Blast: '/imgs/cawh/bright_nights/blast.jpg',
            Condor: '/imgs/cawh/bright_nights/condor.jpg',
            'Crazy River': '/imgs/cawh/bright_nights/crazyriver.jpg',
            'Eat My Dust': '/imgs/cawh/bright_nights/eatmydust.jpg',
            'El Rio Grande': '/imgs/cawh/bright_nights/elriogrande.jpg',
            Goliath: '/imgs/cawh/bright_nights/goliath.jpg',
            'Lost Gravity': '/imgs/cawh/bright_nights/lostgravity.jpg',
            'Space Shot': '/imgs/cawh/bright_nights/spaceshot.jpg',
            'Speed of Sound': '/imgs/cawh/bright_nights/speedofsound.jpg',
            Untamed: '/imgs/cawh/bright_nights/untamed.jpg',
            'Xpress: Platform 13': '/imgs/cawh/bright_nights/xpress.jpg',
            "Merlin's Magic Castle": '/imgs/cawh/bright_nights/merlin.jpg'
        },
        hfn: {
            Blast: 'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fblast2.jpg?alt=media&token=079e3d29-6c7c-41cc-b241-421f258e0053',
            Condor: 'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fcondor.jpg?alt=media&token=ac807868-730c-4bf0-ac33-ac1588205068',
            'Crazy River':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fcrazyriver.jpg?alt=media&token=5f8a4f1a-73b3-41ca-981a-cb8f29e527a9',
            'Eat My Dust':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Featmydust.jpg?alt=media&token=e186ad3c-824a-4809-8b66-811ac936384c',
            'El Rio Grande':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Felriogrande.jpg?alt=media&token=9e5b8a44-cdea-4a79-bea6-af1b88006165',
            Goliath:
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fgoliath.jpg?alt=media&token=b6987fb6-9b0d-4480-90fa-b13425bed401',
            'Lost Gravity':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Flostgravity.jpg?alt=media&token=0b000e59-be78-4b27-8c38-f798507a5470',
            'Space Shot':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fspaceshot.jpg?alt=media&token=e5d6ab80-f335-4a00-abaf-6a9ad234079b',
            'Speed of Sound':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fspeedofsound.jpg?alt=media&token=e465b3da-c9c0-4530-9fd0-1223db11f3ea',
            UNTAMED:
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Funtamed.jpg?alt=media&token=35b2f2bd-b7f5-4cd6-bfcc-04f46a5946bd',
            'Xpress: Platform 13':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fxpress.jpg?alt=media&token=69dc929a-ed38-4b53-8fc0-fc0ad30ee1fb',
            "Merlin's Magic Castle":
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Ftiles%2Fmerlin1.jpg?alt=media&token=6d4226a0-2116-41f3-aef7-cd215b93ac50'
        },
        'hfn-thumbs': {
            Blast: 'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fblast2.jpg?alt=media&token=079e3d29-6c7c-41cc-b241-421f258e0053',
            Condor: 'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fcondor.jpg?alt=media&token=ac807868-730c-4bf0-ac33-ac1588205068',
            'Crazy River':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fcrazyriver.jpg?alt=media&token=5f8a4f1a-73b3-41ca-981a-cb8f29e527a9',
            'Eat My Dust':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Featmydust.jpg?alt=media&token=e186ad3c-824a-4809-8b66-811ac936384c',
            'El Rio Grande':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Felriogrande.jpg?alt=media&token=9e5b8a44-cdea-4a79-bea6-af1b88006165',
            Goliath:
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fgoliath.jpg?alt=media&token=b6987fb6-9b0d-4480-90fa-b13425bed401',
            'Lost Gravity':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Flostgravity.jpg?alt=media&token=0b000e59-be78-4b27-8c38-f798507a5470',
            'Space Shot':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fspaceshot.jpg?alt=media&token=e5d6ab80-f335-4a00-abaf-6a9ad234079b',
            'Speed of Sound':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fspeedofsound.jpg?alt=media&token=e465b3da-c9c0-4530-9fd0-1223db11f3ea',
            UNTAMED:
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Funtamed.jpg?alt=media&token=35b2f2bd-b7f5-4cd6-bfcc-04f46a5946bd',
            'Xpress: Platform 13':
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fxpress.jpg?alt=media&token=69dc929a-ed38-4b53-8fc0-fc0ad30ee1fb',
            "Merlin's Magic Castle":
                'https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/hfn-ride-images%2Fthumbs%2Fmerlin1.jpg?alt=media&token=6d4226a0-2116-41f3-aef7-cd215b93ac50'
        }
    }
};
