import React, { useContext } from 'react';
import { WaitTimeScreenTable } from './WaitTimeScreenTable';
import { useParam } from '../../../../../hooks/useParam';
import { ThemeContext } from '../../../../../context/ThemeContext';

/**
 * Displaying the waiting time screen in landscape mode on top of a background image.
 * Live on various locations in the park.
 */
export const WaitTimeScreenTableLandscape = () => {
    const theme = useContext(ThemeContext);
    return (
        <div
            className={`w-[1920px] h-[1080px] ${theme === 'halloween' ? `bg-black` : `bg-white`}`}
            style={{
                backgroundImage: `url(
                ${
                    theme === 'halloween'
                        ? `https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/landscape-hfn.png?alt=media&token=9d558524-a3f4-4689-a0de-d46d3ba59bae`
                        : `https://firebasestorage.googleapis.com/v0/b/walibi-fcbec.appspot.com/o/landscape-regular.png?alt=media&token=6dd0acc2-dc60-442a-819e-6017af9cccaf`
                }
                )`,
                backgroundSize: 'cover'
            }}
        >
            <div className={`pt-[300px]`}>
                <WaitTimeScreenTable scale="scale-[115%]" />
            </div>
        </div>
    );
};
